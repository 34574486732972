@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.profile {
  @include mp-null;
  margin: 40px auto 140px;
  font-family: $font-family;
  color: $black;
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
  }
  &__tab {
    flex: 2;
    flex-shrink: 1;
    box-sizing: border-box;
    width: 740px;
    height: 100%;
    padding: 50px 40px 70px 40px;
    border-radius: 40px;
    border: 2px solid $black;
    background-color: $main-white;
  }
  &__back {
    display: none;
  }
}

@media (max-width: 1150px) {
  .profile__menu {
    display: none;
  }
}

@include tablet {
  .profile {
    &__tab {
      border: none;
      border-radius: 0;
      padding: 50px 20px 70px 20px;
    }
    &__back {
      display: flex;
      align-items: center;
      max-width: fit-content;
      margin-bottom: 30px;
      text-decoration: none;
      color: $black;
      transition: all 0.3s ease;
      &-svg path {
        fill: $black;
        stroke: $black;
        transition: all 0.3s ease;
      }

      &:hover {
        color: $pink;
        .profile__back-svg path {
          fill: $pink;
          stroke: $pink;
        }
      }

      &:active {
        color: $green;
        .profile__back-svg path {
          fill: $green;
          stroke: $green;
        }
      }
    }
  }
}

@include mobile {
  .profile {
    width: 100%;
    max-width: 100vw;
    margin-bottom: 0px;
    &__back {
      @include use-font(16px, 20px, 400);
    }
    &__tab {
      padding-top: 0px;
      width: calc(100% - 40px);
    }
  }
}
