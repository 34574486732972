@import "./../../scss/mixins.scss";

.contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 40px 160px 0;
  margin: 0 auto;
  max-width: 1440px;
  box-sizing: border-box;

  &__overlay {
    width: calc(100% + 320px);
    transform: translateX(-160px);
    max-width: 1440px;
    min-height: 764px;
    align-self: normal;
    background: url("../../images/contact-overlay.jpg") 100% 100% no-repeat;
  }
  &__list {
    @include mp-null;
    @include flex-box-row;
    padding: 32px 0 60px;
    box-sizing: border-box;
  }
  &__team {
    max-width: 1025px;
    display: grid;
    grid-template-columns: 1fr minmax(390px, 641px);
    grid-template-rows: 1fr;
    column-gap: 115px;
    row-gap: 20px;
    padding: 140px 0 170px;
    margin: 0 auto;
    box-sizing: border-box;
    grid-template-areas:
      "img   header"
      " img   info";
    &_info {
      @include mp-null;
      @include text-font-normal;
      font-weight: 300;
      grid-area: info;
    }
    &_img {
      grid-area: img;
      width: 265px;
      height: 265px;
    }

    &_header {
      @include mp-null;
      @include text-font-normal;
      font-weight: 500;
      text-transform: uppercase;
      grid-area: header;
    }
  }
}

@include tablet {
  .contacts {
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    &__overlay {
      width: calc(100% + 2%);
      transform: translateX(-1%);
      background-position: center;
    }
    &__list {
      padding-top: 20px;
      flex-direction: column;
      align-items: center;
    }
    &__team {
      grid-template-columns: minmax(320px, 1fr);
      padding-top: 80px;
      padding-bottom: 80px;
      row-gap: 28px;
      grid-template-areas:
        "header"
        "img"
        "info";
      text-align: center;
      &_info {
        @include mp-null;
        @include text-font-link-mob;
        // плюсуется с  row-gap: 28px;
        padding-top: 30px;
      }
      &_img {
        margin: auto;
        width: 100%;
        height: 100%;
        max-width: 334px;
        max-height: 334px;
      }
    }
  }
}

@include mobile-min {
  .contacts {
    &__overlay {
      width: calc(100% + 40px);
      min-height: 801px;
      transform: translateX(-20px);
      background: url("../../images/contacts-photo-mob.jpg") 100% 100% no-repeat;
      background-position: center;
    }
    &__team {
      text-align: start;
      &_img {
        margin: 0;
      }
    }
  }
}
