@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.points-list {
  @include mp-null;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;

  &_compact {
    flex-direction: row;
  }
}

.points-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
}

@include laptop {
  .points-list {
    flex-direction: row;
  }
}
