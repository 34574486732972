@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.events {
  font-family: $font-family;
  color: $black;
  max-width: 1120px;
  &__subheader {
    @include mp-null;
    margin: 14px 0 32px;
    max-width: 600px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  &__list {
    @include mp-null;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    list-style-type: none;
    margin: 0 auto;
  }
}

.swiper {
  &-container {
    margin-left: 0;
    height: calc(100% + 20px);
  }
  &-pagination-bullet {
    background-color: $gray;
    &-active {
      background-color: #71797b;
    }
  }

  &-pagination {
    position: static;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 6px;
  }

  &-slide {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
