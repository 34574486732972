// Цвета
$main-light-grey: #efefef;
$main-white: #fdf8f1;
$main-black: #122023;
$green: #b0ff1a;
$pink: #fda8dc;
$black: #122023;
$dark-gray: #71797b;
$gray: #b8bcbd;
$light-gray: #e7e9e9;
$white: #fff;
$alert-red: #f24e1e;
$alert-light-red: #fcdcd2;
$warning: #ffea30;

// Шрифты
$font-family: "Onest", Arial, sans-serif;
$font-family-accent: "MursGothic", Arial, sans-serif;

// Отступы
$main-margin: 40px 160px 140px;
$main-margin-mobile: 40px 20px;
$main-margin-tablet: 20px 100px;
$desktop-gap: 140px;

//Брейкпоинты
$mobile-breakpoint-small: 429px;
$mobile-breakpoint-min: 431px;
$mobile-breakpoint-medium: 480px;
$mobile-breakpoint-large: 680px;
$mobile-breakpoint: 767px;
$tablet-breakpoint: 1024px;
$desktop-breakpoint: 1250px;
