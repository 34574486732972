@import "../../scss/mixins.scss";
@import "../../scss/variables.scss";

.calendar {
  max-width: 930px;
  box-shadow:
    inset 2px 0 $main-black,
    inset -2px 0 $main-black,
    inset 0 -2px $main-black;
  border-radius: 40px;
  position: relative;

  &__header {
    min-height: 82px;
    padding: 6px 48px 4px;
    border: 2px solid $main-black;
    border-radius: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $main-black;

    &-title {
      @include mp-null;
      @include text-font-normal;
      padding-top: 0;
      font-weight: 500;
      color: $white;

      @include mobile {
        line-height: 1.2;
      }

      @include mobile-small {
        @include text-font-link-mob;
      }
    }

    @include mobile {
      margin: 0 29px;
      min-height: 48px;
      padding: 0 14px 0 20px;
    }
  }

  &__body {
    width: 100%;
    padding: 16px 28px 26px 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    @include mobile {
      padding: 5px 16px 0 13px;
    }
  }

  &__cell {
    @include text-font-normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    aspect-ratio: 126 / 80;

    &_type_header {
      cursor: auto;
    }

    @include mobile {
      // @include text-font-link-mob;
      aspect-ratio: 126 / 123;
    }

    @include mobile-medium {
      @include text-font-link-mob;
    }
  }

  &__button {
    @include mp-null;
    @include text-font-normal;
    width: 39px;
    height: 39px;
    border-radius: 50px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s ease;

    &_highlighted {
      color: $dark-gray;
      background-color: $main-black;
    }

    &_previous-month {
      color: $light-gray;
    }

    &_inaccessible-days {
      color: $gray;
      pointer-events: none;
    }

    &_next-month {
      color: $dark-gray;
    }

    @include mobile-medium {
      @include text-font-link-mob;
    }
  }

  &__navigation {
    display: flex;
    gap: 20px;

    &-button {
      @include mp-null;
      min-width: 58px;
      min-height: 58px;
      border: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease;
      cursor: pointer;

      &_type_previous {
        background-image: url("../../images/Calendar-button-chevron.svg");
        background-size: 120%;
        background-position: center;
      }

      &_type_next {
        background-image: url("../../images/Calendar-button-chevron.svg");
        background-size: 120%;
        background-position: center;
        transform: scale(-1, 1);
      }

      &:hover {
        opacity: 0.7;
      }

      @include mobile {
        min-width: 32px;
        min-height: 32px;
      }
    }
  }

  @include mobile {
    border-radius: 0;
    box-shadow: none;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -17px;
      height: 2px;
      width: 84%;
      background: $main-black;
      transform: translateX(-50%);
    }
  }
}
