@import url("./vendor/fonts/fonts.css");
@import url("./vendor/normalize.css");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  background-color: #fdf8f1;
}

#root {
  max-width: 1440px;
  margin: auto;
  min-height: 100vh;
}

@keyframes render-cards {
  0% {
    transform: translateX(70%);
  }

  100% {
    transform: translateX(0);
  }
}
