@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.questions {
  @include mp-null;
  display: flex;
  flex-direction: column;

  &__list {
    @include mp-null;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 32px;
  }
}
