@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.bookings {
  font-family: $font-family;
  font-style: normal;
  color: $black;
  text-align: left;
  &__title {
    @include mp-null;
    text-align: left;
    font-size: 28px;
    font-weight: 300;
    line-height: 42px;
  }
  &__card-list {
    @include mp-null;
    list-style-type: none;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__tabs {
    box-sizing: border-box;
    max-width: 600px;
    margin-top: 30px;
    border-radius: 34px;
    border: 2px solid $black;
    display: flex;
    padding: 8px;
  }
  &__tab-button {
    width: 295px;
    cursor: pointer;
    background-color: transparent;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    padding: 10px 95px;
    border: 2px solid transparent;
    border-radius: 24px;
    transition: 0.5s all ease;
    &_active {
      background-color: $black;
      color: $white;
    }
  }
  &__nodata,
  &__loading {
    margin-top: 40px;
    display: block;
    width: 100%;
    text-align: center;
  }
  &__button-container {
    margin: 0 auto;
    text-align: center;
  }
}

@include tablet {
  .bookings {
    &__tabs {
      margin: 30px auto 0;
    }
  }
}

@include mobile {
  .bookings {
    &__tabs {
      max-width: 500px;
    }
    &__tab-button {
      @include use-font(14px, 22px, 300);
      padding: 5px 30px;
    }
  }
}
