@import "../../scss/variables.scss";
@import "../../scss/mixins.scss";

.payments {
  @include main-box-centered;
  width: auto;
  margin: $main-margin;
  box-sizing: border-box;

  &__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  &-place {
    @include flex-box-column;
    border-radius: 20px;
    border: 2px solid $black;
    padding: 40px 20px 56px 40px;
    box-sizing: border-box;
    margin-top: 5vh;

    &__header {
      @include mp-null;
      font-family: $font-family;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
    &__info {
      @include mp-null;
      @include text-info;
    }
    &__list {
      @include mp-null;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 12px;
      list-style-type: none;
    }
    &__list-item {
      @include text-font-select;
      width: 100%;
      display: flex;
      gap: 5px;
      justify-content: space-between;
    }
    &__bill {
      font-family: $font-family;
      display: flex;
      justify-content: space-between;
      max-width: 293px;
      font-size: 28px;
      font-style: normal;
      font-weight: 300;
      line-height: 42px;
    }
  }

  &__popup-text {
    @include text-font-select;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 400;
  }

  &__popup-title {
    @include mp-null;
    margin-bottom: 20px;
    font-family: $font-family-accent;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0.28px;
  }
}

@include tablet {
  .payments {
    margin: $main-margin-tablet;
    &__container {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}

@include mobile {
  .payments {
    margin: $main-margin-mobile;
  }
}
