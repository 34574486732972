@import "../../scss/variables.scss";
@import "../../scss/mixins.scss";

.popup {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
  z-index: 4;
  font-family: $font-family;
  overflow: scroll;

  &__container {
    height: fit-content;
    min-width: 200px;
    max-width: 520px;
    width: calc(100% - 160px);
    border-radius: 20px;
    box-sizing: border-box;
    background: $main-white;
    padding: 80px;
    position: relative;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    &_reviews-form {
      max-width: 740px;
      width: calc(100% - 190px);
      padding: 60px 95px 92px;
    }
    &_register-form {
      transform: translateY(15%);
    }

    &_payments-message {
      @include flex-box-column-center;
      min-width: 740px;
      padding-top: 50px;
      padding-bottom: 70px;

      @include tablet {
        min-width: auto;
      }
    }
  }
  &__title {
    @include use-font(40px, 48px, 400);
  }
  &__subtitle {
    @include mp-null;
    color: $main-black;
    @include use-font(28px, 42px, 300);
  }
  &__text {
    @include mp-null;
    @include use-font(20px, 30px, 400);
  }
}

@include tablet-with-minwidth {
  .popup {
    &__container {
      width: calc(100vw - 120px);
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}

@include mobile {
  .popup {
    &__container {
      width: calc(100vw - 100px);
      padding-left: 50px;
      padding-right: 50px;
    }
    &_type_whitemobile {
      background-color: white;
      width: calc(100vw - 40px);
    }
  }
}

@include mobile-min {
  .popup {
    &__container {
      min-width: calc(100vw - 40px);
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 30px;
    }
  }
}
