@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

[class*="ymaps-2-1"][class*="-ground-pane"],
[class^="ymaps-2-1"][class$="-gotoymaps__container"] {
  filter: grayscale(1) sepia(15%);
  -ms-filter: grayscale(1) sepia(15%);
  -webkit-filter: grayscale(1) sepia(15%);
  -moz-filter: grayscale(1) sepia(15%);
  -o-filter: grayscale(1) sepia(15%);
}

[class^="ymaps-2-1"][class$="-balloon__layout"],
[class^="ymaps-2-1"][class$="-balloon__content"],
[class^="ymaps-2-1"][class$="-balloon__tail"]:after {
  background-color: $main-white;
}

[class^="ymaps-2-1"][class$="-balloon__close-button"] {
  position: relative;
  top: 0px;
  right: 15px;
}

[class*="-balloon"] {
  border-radius: 25px;
  max-width: 300px;
}

.map {
  @include mp-null;
  font-family: $font-family;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  background-color: grey;
  border-radius: 40px;
  overflow: hidden;
  font-family: $font-family;
  color: $black;
  &_active {
    display: block;
  }
  &_inactive {
    display: none;
  }
  &__container {
    text-align: right;
  }

  &__name {
    font-family: $font-family;
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__image {
    margin-top: 28px;
    margin-left: 14px;
    border-radius: 10px;
    position: relative;
    width: 250px;
    object-fit: cover;
    z-index: 1000;
  }

  &__body-wrapper {
    margin: 0 16px;
    width: 250px;
  }

  &__row {
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__rating-container {
    display: flex;
    align-items: center;
  }

  &__address-container {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
  }

  &__rating {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 0.8;
    padding: 0;
    margin: 0;
    margin-right: 4px;
  }

  &__address {
    font-family: $font-family;
    margin-left: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
  }

  &__icon {
    margin: 0;
    padding: 0px;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  &__button {
    font-family: $font-family;
    margin: 8px 0;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    transition: 0.3s all ease;

    &:hover {
      color: $pink;
    }
    &:active {
      color: $green;
    }
  }
}
