@import "../../scss/variables.scss";
@import "../../scss/mixins.scss";

.booking-calendar-popup {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;

  &__container {
    height: auto;
    position: absolute;
    top: 100px;
    width: 90.7%;
    padding: 63px 0px 78px;
    background: $white;
    border: 1.5px solid $main-black;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__close-button {
    left: 20px;
  }

  &__controls-wrapper {
    padding: 16px 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__save-button {
    width: 100%;
    align-self: center;
  }
}
