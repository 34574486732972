@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.equipment-item {
  color: $black;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 40px;
  max-width: 835px;

  &__title {
    @include mp-null;
    font-family: $font-family;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
  }

  &__list {
    @include mp-null;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  &__list-item {
    display: flex;
    gap: 12px;
    max-height: 30px;
    text-wrap: nowrap;
  }

  &__text {
    @include mp-null;
    font-family: $font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }

  &__img {
    width: 32px;
    height: 32px;
  }
}

@include mobile {
  .equipment-item {
    gap: 16px;

    &__title {
      font-size: 24px;
    }

    &__text {
      font-size: 14px;
      font-weight: 300;
    }

    &__list {
      gap: 12px;
    }

    &__img {
      width: 20px;
      height: 20px;
    }
  }
}
