@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.profile-dashboard {
  max-width: 930px;
  margin: 50px auto 0;

  &__card-list {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 140px;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__card-row {
    flex: 1;
    display: flex;
    gap: 20px;
  }

  &__card-center {
    max-width: 930px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
  &__link {
    text-decoration: none;
    stroke: $white;
    color: $white;
    &:hover {
      stroke: $pink;
      color: $pink;
    }
    &:active {
      stroke: $black;
      color: $black;
    }
  }
}

@include tablet {
  .profile-dashboard {
    margin: 40px auto 0;
    max-width: calc(100vw - 40px);
    &__card-row {
      flex-direction: column;
    }
    &__card-list {
      margin-bottom: 80px;
    }
    &__link {
      margin: 0 auto;
    }
  }
}
