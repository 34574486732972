@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.security {
  font-family: $font-family;
  &__title {
    @include mp-null;
    max-width: 323px;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 42px;
  }
  &__board {
    @include mp-null;
    margin-top: 30px;
    list-style-type: none;
    &-row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .input__text {
        display: none;
      }
    }
  }
  &__popup {
    min-width: 740px;
    min-height: 276px;
    text-align: center;
    padding: 60px 95px 95px 95px;
  }
  &__popup-content {
    text-align: left;
  }
  &__popup-text {
    @include mp-null;
    max-width: 641px;
    @include use-font(20px, 30px, 400);
    margin-bottom: 24px;
  }

  &__password-container,
  &__sessions-container,
  &__exit-container,
  &__delete-container {
    width: 340px;
  }
  &__delete-container {
    color: #f24e1e;
  }
  &__password-container {
    display: flex;
    align-items: center;
    gap: 68px;
  }
  &__feature-name {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  &__feature-description {
    @include mp-null;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
  }
  &__input {
    box-sizing: border-box;
    width: calc(100vw - 40px);
    max-width: 450px;
    height: 44px;
    padding: 22px 20px 4px 20px;
  }
  &__button-container {
    margin: 24px auto 0;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

@include mobile {
  .security {
    &__board-row {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 30px;
    }
    &__password-container,
    &__sessions-container,
    &__exit-container,
    &__delete-container {
      width: 100%;
      max-width: 450px;
    }
    &__popup {
      min-width: calc(100vw - 40px);
      padding: 50px 45px 80px;
      &-text {
        @include use-font(14px, 22px, 300);
      }
    }
    &__button-container {
      flex-direction: column;
      gap: 8px;
    }
    &__feature-name {
      @include use-font(16px, 24px, 400);
    }
  }
}
