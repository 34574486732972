@import "../../../../scss/mixins.scss";
@import "../../../../scss/variables.scss";

.button__profile {
  box-sizing: border-box;
  background-color: $white;
  max-width: 360px;
  height: 84px;
  padding: 20px 20px 20px 78px;
  text-align: left;
  border: 2px solid $black;
  border-radius: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
    border-color: $main-white;
    opacity: 1;
  }
  &:active {
    color: $white;
    background-color: $black;
  }
  &-favorites {
    @include use-font(18px, 22px, 400);
    background: transparent;
    width: 100%;
    @include link-color-transition;
  }
}

$tabNames: "data", "security", "bookings", "favorites";

@each $tabName in $tabNames {
  .button__profile_#{$tabName} {
    @include button-with-image(
      "../../../../images/profile-icons/#{$tabName}.svg"
    );
    &:hover {
      @include button-with-image(
        "../../../../images/profile-icons/#{$tabName}.svg"
      );
    }
    &:active {
      @include button-with-image(
        "../../../../images/profile-icons/#{$tabName}-active.svg"
      );
    }
  }

  .button__profile_#{$tabName}-active {
    @include button-with-image(
      "../../../../images/profile-icons/#{$tabName}-active.svg"
    );
    &:active {
      @include button-with-image(
        "../../../../images/profile-icons/#{$tabName}-pressed.svg"
      );
    }
    @include button-active;
  }
}

.button__profile_security {
  line-height: 22px;
}
