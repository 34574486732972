@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.point {
  @include card-border-color;
  @include render-animation;
  max-width: 360px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 47px;
  border: 2px solid $black;
  background-color: $main-white;
  overflow: hidden;
  box-sizing: border-box;

  &_own-page {
    border: none;
    border-radius: 0;
    &:hover {
      box-shadow: none;
    }
  }

  &__span {
    font-family: $font-family;
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.37;
  }

  &_large {
    flex-direction: row;
    max-width: 1120px;
    max-height: 460px;
    border-radius: 40px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__image-container {
    position: relative;
    width: 360px;
    height: 260px;
    box-sizing: border-box;

    &_large {
      width: 455px;
      height: 460px;
    }

    &_own-page {
      width: 740px;
      height: 450px;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    aspect-ratio: 4/3;

    &_large {
      width: 455px;
      height: 460px;
    }

    &_own-page {
      width: 740px;
      height: 450px;
    }

    &_compact {
      height: 260px;
    }
  }

  &__cost {
    @include mp-null;
    position: absolute;
    padding: 8px 12px;
    top: 20px;
    left: 20px;
    background-color: $white;
    border-radius: 20px;
    font-family: $font-family;
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.37;

    &_own-page {
      top: 40px;
      left: 17px;
    }
  }

  &__info-buttons {
    display: flex;
    gap: 8px;
    position: absolute;
    top: 40px;
    right: 17px;
    background-color: $main-white;
    border-radius: 20px;
    padding: 8px 12px;
    box-sizing: border-box;
    max-height: 38px;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;

    :first-child {
      flex-shrink: 0;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 20px;
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    @include mp-null;
    font-family: $font-family;
    color: $black;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
  }

  &__subtitle-container {
    padding: 12px 0 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
  }

  // &__subtitle {
  //   @include mp-null;
  //   font-family: $font-family;
  //   color: $black;
  //   font-size: 16px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: 1.37;
  // }

  &__action-button {
    @include mp-null;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  &__about {
    @include mp-null;
    font-family: $font-family;
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.57;
  }

  &__info {
    @include mp-null;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    list-style: none;
    max-width: 285px;

    &_large {
      max-width: 550px;
      gap: 12px;
    }
  }

  &__info-text {
    @include mp-null;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: wrap;
    font-family: $font-family;
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.37;
    max-height: 45px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
  }
}

.z-index-2 {
  z-index: 2;
}

.swiper-pagination-points {
  position: absolute;
  bottom: 35px;
  left: 60%;
  z-index: 10;
  display: flex;
  gap: 6px;
  max-width: 100%;

  .swiper-pagination-bullet {
    background-color: $light-gray;
    width: 9px;
    height: 9px;
    opacity: 1;
    z-index: 10;

    &-active {
      background-color: $dark-gray;
    }
  }
}

@include laptop {
  .point {
    &__info-container {
      gap: 16px;
    }
    &__subtitle-container {
      gap: 15px;
    }

    &_large {
      flex-direction: column;
      max-width: 390px;
      max-height: 100%;
      border-radius: 42px;
    }

    &_own-page {
      flex-direction: column;
      max-width: 390px;
      max-height: 562px;
    }

    &__image {
      width: 390px;
      height: 300px;

      &_compact {
        height: 260px;
      }
    }

    &__image-container {
      width: 390px;
      height: 300px;
    }

    &__buttons {
      justify-content: center;
      flex-direction: column;
    }

    &__cost {
      &_own-page {
        top: 20px;
        left: 17px;
      }
    }

    &__info-buttons {
      top: 20px;
    }
  }
}
