@import "../../../scss/variables.scss";
@import "../../../scss/mixins.scss";
.button {
  font-family: $font-family;
  width: 360px;
  outline: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease;
  padding: 0;
  margin: 0;
  text-align: center;
  opacity: 1;
  border-radius: 32px;
  padding: 12px 24px;

  &__promo {
    border: 2px solid $black;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    max-width: 170px;
    max-height: 54px;
    color: $black;
    background-color: $green;

    &:hover {
      color: $black;
      background-color: $green;
    }

    &:active {
      color: $green;
      background-color: $black;
    }
  }

  // &_location_search-form-search {
  //   border: 2px solid $black;
  //   border-top-left-radius: 0;
  //   border-bottom-left-radius: 0;
  //   border-left: none;
  //   max-width: 170px;
  //   max-height: 54px;
  // }

  &__subscription {
    border-radius: 32px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    max-width: 190px;
    height: 58px;
    background-color: $green;
  }

  &:disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  &_active {
    border: 2px solid $black;
    background-color: $light-gray;
  }

  &_type_close {
    background: url("../../../images/close-icon.svg") no-repeat;
    background-size: cover;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    padding: 0;
  }

  &_box {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &_more {
    margin-top: 32px;
  }

  &_type_tertiary-map {
    color: $black;
    background-color: transparent;
    padding: 16px 12px 16px 36px;
    margin-bottom: 12px;
    @include use-font(18px, 1.22, 400);
    text-align: right;
    width: 195px;
    align-self: flex-end;
    background: url("../../../images/tertiary-icon.svg");
    background-repeat: no-repeat;
    background-position: 16px 12px;
    transition: none;

    &-shown {
      width: 177px;
    }

    &:hover {
      background: url("../../../images/tertiary-icon-hover.svg");
      background-repeat: no-repeat;
      background-position: 16px 12px;
      color: $pink;
      stroke: $pink;
    }

    &:active {
      background: url("../../../images/tertiary-icon-active.svg");
      background-repeat: no-repeat;
      background-position: 16px 12px;
      color: $green;
      stroke: $green;
    }
  }

  &_type_tertiary {
    color: $black;
    background-color: transparent;
    padding: 16px 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.22;
    width: 220px;
    border: none;

    &:hover {
      color: $pink;
      opacity: 0.7;
    }

    &:active {
      color: $green;
      opacity: 0.7;
    }
  }

  &_type_show-filters {
    color: $black;
    background-color: transparent;
    padding: 16px 12px 16px 36px;
    margin-bottom: 12px;
    font-size: 18px;
    text-align: right;
    font-style: normal;
    font-weight: 400;
    line-height: 1.22;
    width: 271px;
    align-self: flex-end;
    background: url("../../../images/filter-icon.svg");
    background-repeat: no-repeat;
    background-position: 63px 12px;
    transition: none;

    &-shown {
      width: 177px;
    }

    &:hover {
      background: url("../../../images/filter-icon-hover.svg");
      background-repeat: no-repeat;
      background-position: 16px 12px;
      color: $pink;
      stroke: $pink;
    }

    &:active {
      background: url("../../../images/filter-icon-active.svg");
      background-repeat: no-repeat;
      background-position: 16px 12px;
      color: $green;
      stroke: $green;
    }
  }

  &_type_form {
    background-color: $green;
    color: $black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.22;
    padding: 16px 32px;

    &-select {
      width: 170px;
      @include text-font-select;
      padding: 16px 32px;
    }

    &_margin-top {
      margin-top: 32px;
    }

    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      opacity: 0.8;
      pointer-events: none;
      background: $light-gray;
    }

    &:active {
      background-color: $black;
      color: $green;
      opacity: 1;
    }
  }

  &_type_link {
    font-family: $font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    border: none;
    background: none;
    padding: 16px 12px;
    margin-top: 12px;
    &-password {
      font-size: 16px;
      line-height: 22px;
    }
    &:hover {
      color: $pink;
    }
  }

  &_type_link-underline {
    @extend .button_type_link;
    margin-top: 0;
    padding: 10px 0 4px;
    width: auto;
    border-radius: 0;
    border-bottom: 2px solid $green;
    align-self: flex-start;
  }

  &_type_transparent {
    border: 2px solid $black;
    background-color: $main-white;
    color: $black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    &-select {
      width: 170px;
      @include text-font-select;
    }

    &:hover {
      border: 2px solid $pink;
      color: $pink;
    }

    &:active {
      border: 2px solid $black;
      background: $pink;
      color: $black;
    }

    @include mobile {
      border: 1.5px solid $black;

      &:hover {
        border: 1.5px solid $pink;
      }

      &:active {
        border: 1.5px solid $black;
      }
    }
  }

  //стили кнопки пароля
  &__password-toggle {
    @include mp-null;
    width: 24px;
    height: 24px;
    background-color: $white;
    position: absolute;
    top: 16px;
    right: 20px;

    &-active {
      background: url("../../../images/visible-password-icon.svg") no-repeat;
      background-size: 100%;
    }

    &-hide {
      background: url("../../../images/hide-pass-icon.svg") no-repeat;
      background-size: 100%;
    }
  }

  &_type_review {
    background: none;
    padding: 0;
    display: contents;
  }

  // Кнопка назад на странице коворкинга
  &_type_back {
    width: auto;
    padding: 4px 24px 22px 36px;
    background: url("../../../images/back-button.svg");
    background-position: left top;
    background-repeat: no-repeat;
    box-sizing: border-box;
    font-family: $font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.22;

    &:hover {
      opacity: 0.7;
      color: $pink;
      fill: $pink;
    }

    @include mobile {
      @include text-font-normal-mob;
      font-weight: 400;
      line-height: 1.25;
      padding: 10px 24px 10px 32px;
      margin-bottom: 26px;
      background-position: left center;
      background-size: 24px;
    }
  }

  &_size {
    &_mini {
      width: 75px;
    }
    &_small {
      width: 148px;
    }
    &_small-s {
      width: 118px;
    }
    &_medium {
      width: 245px;
    }
    &_middle {
      width: 265px;
    }
    &_postmiddle {
      max-width: 100%;
    }
    &_large {
      width: 550px;
    }
  }

  &__profile-small {
    width: 170px;
  }

  &__profile-review {
    width: 265px;
    height: 54px;
    @include primary-button-black;
    @include use-font(18px, 22px, 400);
  }

  &__profile-edit {
    @include primary-button-black;
    @include use-font(18px, 22px, 400);
    @include profile-size-button;
  }

  &__profile-transparent {
    @include profile-size-button;
    background-color: $green;
    @include use-font(18px, 22px, 400);
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      background-color: $black;
      color: $green;
      opacity: 1;
    }

    &:disabled {
      background-color: $light-gray;
      color: $gray;
    }
  }

  &_type_security {
    @include use-font(18px, 22px, 400);
    @include primary-button-black;
    height: 54px;
  }
  &_type_order,
  &_type_delete {
    @include profile-size-button;
    @include use-font(18px, 22px, 400);
    @include primary-button-black;
  }
  &_type_createorder {
    @include profile-size-button;
    @include use-font(18px, 22px, 400);
    @include primary-button;
  }
  &_type_cancel {
    @include secondary-button;
  }

  &_type_sendreview {
    background-color: transparent;
    &:hover {
      color: $pink;
    }
    &:active {
      color: $green;
    }
  }

  &_width-bookings {
    width: 226px;
  }

  &__profile-review {
    width: 265px;
  }

  &__profile-transparent {
    background-color: transparent;
    border-radius: 32px;
    border: 2px solid $black;
  }

  &_type_booking {
    width: auto;
  }

  &_type_buttons-list {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    background: transparent;

    @include mobile-medium {
      @include text-font-normal-mob;
      line-height: 1.25;
      padding: 11px 0 10px;
      border: 1.5px solid $main-black;
    }
  }

  &_type_buttons-list:disabled {
    border: 2px solid $gray;
    color: $gray;
    @include mobile {
      border: 1.5px solid $gray;
    }
  }

  &_type_buttons-list-selected {
    background-color: $pink;

    &:hover {
      background-color: $main-white;
    }
  }

  &_type_popup {
    width: auto;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    border-radius: 100vmax;
    border: 2px solid $main-black;
    background-color: $main-black;
    color: $main-white;

    &:hover {
      background-color: transparent;
      color: $main-black;
    }
  }

  &_type_submit-search {
    width: 265px;
    background-color: $black;
    color: $white;

    &:hover {
      color: $black;
      background-color: $green;
    }

    &:active {
      color: $green;
      background-color: $black;
    }
  }

  &_type_not-found {
    width: 245px;
  }

  &:hover {
    opacity: 0.7;
  }

  &_type_point {
    width: 265px;
  }
}

@import "./profile-menu/ProfileButtons.scss";

@include mobile {
  .button {
    &_width-bookings {
      width: 100%;
    }
    &_type_order,
    &_type_createorder,
    &_type_delete {
      @include primary-button-black;
      @include use-font(16px, 20px, 400);
      order: 1;
      height: 44px;
      width: 100%;
      max-width: 450px;
      align-self: center;
    }
    &_type_cancel {
      @include use-font(16px, 20px, 400);
      order: 2;
      height: 44px;
      width: 100%;
      max-width: 450px;
      align-self: center;
    }
    &_type_security,
    &__profile-review {
      @include use-font(16px, 20px, 400);
      width: 100%;
      max-width: 450px;
      height: 44px;
    }
    &_type_submit-search {
      flex: 1;
      border: 2px solid $black;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      // max-width: 170px;
      max-height: 44px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
    }

    &_type_promo-search {
      flex: 1;
      // border: 2px solid $black;
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
      // border-left: none;
      // max-width: 170px;
      max-height: 44px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
    }
    &_type_form {
      @include text-font-normal-mob;
      width: 315px;
      height: 44px;
      padding: 12px;
    }

    &__password-toggle {
      @include mp-null;
      width: 20px;
      height: 20px;
      top: 12px;
      right: 12px;
    }
    &__subscription {
      @include text-font-normal-mob;
      max-width: 120px;
      height: 44px;
      background-color: $green;
      padding: 12px;
    }
    &_type_link {
      @include text-font-normal-mob;
      padding-top: 12px;
      padding-bottom: 12px;
      &-password {
        @include text-font-link-mob;
      }
    }
    &__profile-edit {
      @include use-font(16px, 20px, 400);
      width: calc(100vw - 40px);
      max-width: 450px;
      height: 44px;
    }

    &_type_tertiary-map {
      font-size: 16px;
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 36px 12px;
      &:hover {
        background-size: 20px;
        background-position: 36px 12px;
      }
      &:active {
        background-size: 20px;
        background-position: 36px 12px;
      }
    }

    &_type_show-filters {
      width: 195px;
      font-size: 16px;
      padding-top: 1px;
      background-size: 20px;
      background-position: 12px 1px;

      &:hover {
        background: url("../../../images/filter-icon-hover.svg");
        background-repeat: no-repeat;
        padding-top: 1px;
        background-size: 20px;
        background-position: 12px 1px;
      }

      &:active {
        background: url("../../../images/filter-icon-active.svg");
        background-repeat: no-repeat;
        padding-top: 1px;
        background-size: 20px;
        background-position: 12px 1px;
      }
    }
    &_more {
      max-width: 350px;
    }
    &_type_not-found {
      @include use-font(16px, 20px, 400);
      width: 390px;
    }
    &_type_point {
      width: 350px;
      height: 44px;
    }
  }
}
