@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.contacts-item {
  @include mp-null;
  @include flex-box-column;
  width: 100%;
  max-width: 360px;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: $green;

  &__header {
    @include mp-null;
    @include text-font-normal;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__info {
    @include mp-null;
    @include text-font-normal;
    font-weight: 400;
  }
  &__image {
    width: 58px;
    height: 58px;
  }
}

@include tablet {
  .contacts-item {
    max-width: 390px;
    gap: 12px;
    &__info {
      @include mp-null;
      @include text-font-normal-mob;
    }
    &__header {
      line-height: 24px;
    }
  }
}
