.navbar {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: end;
}

.navbar__menu {
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  -webkit-user-select: none;
  user-select: none;
}
.navbar__menu_opened {
  width: 100%;
}

.navbar_no-active {
  z-index: 0;
  background: none;
  position: static;
}

.navbar__link {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
}

.navbar__checkbox {
  display: block;
  width: 30px;
  height: 32px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  opacity: 0;
  z-index: 4;
  -webkit-touch-callout: none;
}

.navbar__toogle {
  display: block;
  width: 30px;
  height: 2px;
  margin-bottom: 6px;
  position: relative;
  top: 20px;
  right: 20px;
  background: #122023;
  border-radius: 2px;
  z-index: 1;
  transform-origin: 13px 0px;
  transition:
    transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
}

.navbar__toogle:first-child {
  transform-origin: 0% 0%;
}

.navbar__toogle:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.navbar__checkbox:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(0px, 6px);
  background: #232323;
}

.navbar__checkbox:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.navbar__checkbox:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

.navbar__list {
  max-width: 430px;
  width: 100%;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  align-items: end;
  /* border-radius: 10px; */
  box-sizing: border-box;
  background: #fdf8f1;
  padding: 24px 24px 36px 24px;
  bottom: border-box;
  /* box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15); */
  position: relative;
  top: 14px;
  background: #ededed;
  /* dont touch */
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: none;
  margin: 0;
  visibility: hidden;
}

.navbar__link-item {
  padding: 10px 0;
  font-size: 22px;
}

.navbar__checkbox:checked ~ ul {
  visibility: visible;
  background: #fdf8f1;
}
