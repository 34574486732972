@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.coworking-list {
  margin: $main-margin;
  display: flex;
  flex-direction: column;
}

@include laptop {
  .coworking-list {
    margin: $main-margin-tablet;
  }
}

@include mobile {
  .coworking-list {
    margin: $main-margin-mobile;
    margin-top: 47px;
  }
}
